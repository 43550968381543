import { IService } from "@/types/service";
import { getHeaders } from "./headers";

export const getServices = async (published?: boolean): Promise<IService[]> => {
  try {
    const queryParams = new URLSearchParams({
      ...(published !== undefined && { published: String(published) }),
    });

    const response = await fetch(`/api/services?${queryParams}`, {
      method: "GET",
      headers: getHeaders({ auth: true, contentType: true }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching services: ${response.statusText}`);
    }

    const data = await response.json();
    return data.services;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
