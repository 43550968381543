"use client";

import { useTheme } from "next-themes";
import { Moon, Sun } from "lucide-react";

import { Button } from "@/components/ui/button";

export const ToggleTheme = () => {
  const { theme, setTheme } = useTheme();
  return (
    <Button
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
      size="icon"
      variant="ghost"
      className="ml-auto h-8 w-8"
    >
      <div className="flex gap-2 dark:hidden">
        <Moon className="h-4 w-4" />
        <span className="sr-only"> Dark </span>
      </div>

      <div className="dark:flex gap-2 hidden">
        <Sun className="size-4" />
        <span className="sr-only">Light</span>
      </div>
    </Button>
  );
};
